import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useReducer,
} from 'react'
import Chip from '@mui/material/Chip'
import theme from '../../styles/theme'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import InputBase from '@mui/material/InputBase'
import FormHelperText from '@mui/material/FormHelperText/FormHelperText'

export interface InputSectionWithTagsHandle {
  clearValue: () => void
  getTags: () => string[]
}

interface State {
  validateTags?: (tags: string[]) => boolean[]
  tagsArray: string[]
  tagErrors: boolean[]
  inputValue: string
  isInputFocused: boolean
  foregroundColor: string
}

type SetTagsAction = {
  type: 'SET_TAGS'
  payload: string[]
}

type SetInputValueAction = {
  type: 'SET_INPUT_VALUE'
  payload: string
}

type ClearValueAction = {
  type: 'CLEAR_VALUE'
  payload: string[]
}

type SetIsInputFocused = {
  type: 'SET_INPUT_FOCUSED'
  payload: boolean
}

type Actions =
  | SetTagsAction
  | SetInputValueAction
  | ClearValueAction
  | SetIsInputFocused

const reducer = (state: State, action: Actions) => {
  const getForegroundColor = (
    isInputFocused: boolean,
    tagErrors: boolean[]
  ) => {
    return tagErrors.find((e) => e)
      ? theme.palette.error.main
      : isInputFocused
      ? theme.palette.primary.main
      : theme.palette.grey[400]
  }

  switch (action.type) {
    case 'SET_TAGS':
      const tagErrors = state.validateTags?.(action.payload) ?? []
      return {
        ...state,
        tagsArray: action.payload,
        tagErrors,
        foregroundColor: getForegroundColor(state.isInputFocused, tagErrors),
      }
    case 'SET_INPUT_VALUE':
      return {
        ...state,
        inputValue: action.payload,
      }
    case 'CLEAR_VALUE':
      return {
        ...state,
        tagsArray: action.payload,
        inputValue: '',
        tagErrors: [],
        foregroundColor: theme.palette.grey[400],
      }
    case 'SET_INPUT_FOCUSED':
      return {
        ...state,
        isInputFocused: action.payload,
        foregroundColor: getForegroundColor(action.payload, state.tagErrors),
      }

    default:
      return state
  }
}

interface InputSectionWithTagsProps {
  tags: string[]
  label: string
  placeholder?: string
  isDisabled: boolean
  errorMessage: string | null
  validateTags?: (tags: string[]) => boolean[]
  onTagsChanged?: (tags: string[]) => void
}
const InputSectionWithTags: ForwardRefRenderFunction<
  InputSectionWithTagsHandle,
  InputSectionWithTagsProps
> = ({ onTagsChanged, validateTags, ...props }, forwardedRef) => {
  const [state, dispatch] = useReducer(reducer, {
    validateTags,
    tagsArray: props.tags,
    tagErrors: [],
    inputValue: '',
    isInputFocused: false,
    foregroundColor: theme.palette.grey[400],
  })

  const updateTags = useCallback(
    (newTags: string[]) => {
      dispatch({ type: 'SET_TAGS', payload: newTags })
      onTagsChanged?.(newTags)
    },
    [onTagsChanged]
  )

  useImperativeHandle(forwardedRef, () => ({
    clearValue: () => {
      dispatch({ type: 'CLEAR_VALUE', payload: props.tags })
    },
    getTags: () => state.tagsArray,
  }))

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (['Enter', 'Tab', ' '].includes(event.key)) {
      event.preventDefault()
      const newTag = state.inputValue.trim()

      if (newTag) {
        updateTags([...(state.tagsArray || []), newTag])
        dispatch({ type: 'SET_INPUT_VALUE', payload: '' })
      }
    } else if (event.key === 'Backspace' && state.inputValue?.length === 0) {
      updateTags((state.tagsArray || []).slice(0, -1))
    }
  }

  const handleBlur = () => {
    const newTag = state.inputValue.trim()

    if (newTag) {
      updateTags([...(state.tagsArray || []), newTag])
      dispatch({ type: 'SET_INPUT_VALUE', payload: '' })
    }
    dispatch({ type: 'SET_INPUT_FOCUSED', payload: false })
  }

  const handleDelete = (indexToDelete: number) => {
    updateTags(
      (state.tagsArray || []).filter((_, index) => index !== indexToDelete)
    )
  }

  const inputRef = React.useRef<HTMLInputElement>(null)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingY: theme.spacing(1),
      }}
    >
      <FormControl
        color="primary"
        sx={{
          borderWidth: state.isInputFocused ? '2px' : '1px',
          borderStyle: 'solid',
          borderRadius: '4px',
          borderColor: state.foregroundColor,
          padding: theme.spacing(1),
          backgroundColor: theme.palette.common.white,
        }}
        onClick={() => {
          inputRef.current?.focus()
        }}
      >
        <InputLabel
          shrink={Boolean(
            state.tagsArray?.length > 0 ||
              state.inputValue?.length > 0 ||
              state.isInputFocused
          )}
          sx={{
            backgroundColor: theme.palette.common.white,
            paddingX: theme.spacing(0.5),
            '&.MuiInputLabel-shrink': {
              color: state.tagErrors.find((e) => e)
                ? theme.palette.error.main
                : state.isInputFocused
                ? state.foregroundColor
                : props.isDisabled
                ? theme.palette.grey[400]
                : theme.palette.grey[600],
            },
            color:
              state.isInputFocused || state.tagsArray?.length > 0
                ? state.foregroundColor
                : theme.palette.grey[600],
          }}
          disabled={props.isDisabled}
        >
          {props.label}
        </InputLabel>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: theme.spacing(1),
          }}
        >
          {state.tagsArray?.map((tag, index) => (
            <Chip
              key={index}
              color={state.tagErrors?.[index] ? 'error' : 'info'}
              label={tag}
              onDelete={() => handleDelete(index)}
              disabled={props.isDisabled}
              sx={{ alignSelf: 'center' }}
            />
          ))}
          <InputBase
            inputRef={inputRef}
            value={state.inputValue}
            onChange={(e) =>
              dispatch({ type: 'SET_INPUT_VALUE', payload: e.target.value })
            }
            onKeyDown={handleKeyDown}
            onFocus={() =>
              dispatch({ type: 'SET_INPUT_FOCUSED', payload: true })
            }
            onBlur={handleBlur}
            disabled={props.isDisabled}
            placeholder={props.placeholder}
            sx={{ paddingY: theme.spacing(0.5), flex: '1 1 auto' }}
          />
        </Box>
      </FormControl>
      {props.errorMessage && (
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {props.errorMessage}
        </FormHelperText>
      )}
    </Box>
  )
}

export default forwardRef(InputSectionWithTags)
