import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { SxProps, Theme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { FC, useState } from 'react'
import * as yup from 'yup'
import { locale } from '../../locales'
import theme from '../../styles/theme'

const forgotPasswordValidationSchema = yup.object({
  email: yup.string().required('REQUIRED'),
})

const validateForm = (email: string) => {
  try {
    forgotPasswordValidationSchema.validateSync(
      { email },
      { abortEarly: false }
    )
    return ''
  } catch (err: any) {
    return err.message
  }
}

export const ForgotPasswordFragment: FC<{
  cardStyle: SxProps<Theme>
  handleSubmitEmail: (email: string) => void
  errorEmail: string | null
}> = ({ cardStyle, handleSubmitEmail: handleSubmit, errorEmail: error }) => {
  const [email, setEmail] = useState<string>('')
  const [fieldError, setFieldError] = useState<string | null>(null)

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit(email)
      }}
      data-testid="forgot-password-form"
    >
      <Card variant="outlined" sx={cardStyle}>
        <CardContent sx={{ padding: theme.spacing(1) }}>
          <Stack spacing={2}>
            <Typography variant="h5">
              {locale.translation.ForgotPasswordForm.Title}
            </Typography>
            <TextField
              fullWidth
              id="email"
              name="email"
              label={locale.translation.ForgotPasswordForm.Fields.Email.Label}
              placeholder={
                locale.translation.ForgotPasswordForm.Fields.Email.Placeholder
              }
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
                setFieldError(validateForm(e.target.value))
              }}
              error={Boolean(fieldError)}
              helperText={
                fieldError === 'REQUIRED'
                  ? locale.translation.ForgotPasswordForm.Fields.Email
                      .Validation.Required
                  : null
              }
            />
            {error && (
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            )}
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disableElevation={true}
          >
            {locale.translation.ForgotPasswordForm.Buttons.Submit}
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}
