import React, { FC, useContext, useReducer } from 'react'
import { useRecoilValue } from 'recoil'
import * as yup from 'yup'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AdjustmentReasons, AdjustmentReasonCodes } from '../../../constants'
import { locale } from '../../../locales'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { selectedClientState } from '../../../state/SelectedPharmacyState'
import { ProductCurrentStockItem } from '../entities/ProductCurrentStock'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import InputStockForm from './InputStockForm'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import LoadingButton from '@mui/lab/LoadingButton'
import theme from '../../../styles/theme'
import Alert from '@mui/material/Alert'
import FormControl from '@mui/material/FormControl'
import { ProductStockUpdateRequest } from '../entities/ProductStockUpdateMessage'
import Markdown from 'marked-react'

const translation =
  locale.translation.StockTrackingPage.TabLiveStock.AdjustmentForm
const reasonsTranslations = {
  ...locale.translation.DisposalReasons,
  ...locale.translation.InterBranchTransfer,
}

export const validationSchema = (
  selectedProductDetails: ProductCurrentStockItem
) =>
  yup.object({
    //adjustmentReason: yup.string().required('REASON_REQUIRED'),
    adjustedPacks: yup
      .number()
      .integer('INTEGER')
      .required('REQUIRED')
      .min(0, 'MIN')
      .when('adjustmentReason', ([adjustmentReason], schema) => {
        return adjustmentReason === AdjustmentReasonCodes.StockComingIn
          ? schema
          : schema.max(selectedProductDetails!.stockPacks!, 'MAX')
      }),
    adjustedUnits: yup
      .number()
      .integer('INTEGER')
      .required('REQUIRED')
      .min(0, 'MIN')
      .when('adjustedPacks', ([adjustedPacks], schema) => {
        return adjustedPacks === 0 ? schema.not([0], '') : schema
      })
      .when('adjustmentReason', ([adjustmentReason], schema) => {
        return adjustmentReason === AdjustmentReasonCodes.StockComingIn
          ? schema
          : schema.lessThan(
              selectedProductDetails!.packSize!,
              'LESS_THAN_PACK_SIZE'
            )
      }),
  })

export const validateForm = (
  form: FormValues,
  selectedProductDetails: ProductCurrentStockItem
) => {
  try {
    validationSchema(selectedProductDetails).validateSync(form, {
      abortEarly: false,
    })
    return { adjustedPacks: '', adjustedUnits: '', adjustmentReason: '' }
  } catch (err: any) {
    const errors = {
      adjustedPacks: '',
      adjustedUnits: '',
      adjustmentReason: '',
    }
    err.inner.forEach((validationError: any) => {
      if (validationError.path === 'adjustedPacks') {
        errors.adjustedPacks = validationError.message
      } else if (validationError.path === 'adjustedUnits') {
        errors.adjustedUnits = validationError.message
      } else if (validationError.path === 'adjustmentReason') {
        errors.adjustmentReason = validationError.message
      }
    })
    return errors
  }
}

interface AdjustmentMenuProps {
  selectedProductDetails: ProductCurrentStockItem
  onSubmitCallback: () => void
}

interface FormValues {
  adjustedPacks: number | undefined
  adjustedUnits: number | undefined
  adjustmentReason: string
}

interface AdjustmentFormState {
  adjustedPacks: number | undefined
  adjustedUnits: number | undefined
  adjustmentReason: string
  errors: {
    adjustedPacks: string
    adjustedUnits: string
    adjustmentReason: string
  }
  isSubmitting: boolean
  error: string
  expanded: string
  textFieldVisible: boolean
  warning: string
  loading: boolean
}

interface SetPacksActionb {
  type: 'SET_PACKS'
  payload: number | undefined
}

interface SetUnitsAction {
  type: 'SET_UNITS'
  payload: number | undefined
}

interface SetReasonAction {
  type: 'SET_REASON'
  payload: string
}

interface SetFormErrorsAction {
  type: 'SET_FORM_ERRORS'
  payload: {
    adjustedPacks: string
    adjustedUnits: string
    adjustmentReason: string
  }
}

interface SetErrorAction {
  type: 'SET_ERROR'
  payload: string
}

interface SetExpandedAction {
  type: 'SET_EXPANDED'
  payload: string
}

interface SetWarningAction {
  type: 'SET_WARNING'
  payload: string
}

interface SetLoadingAction {
  type: 'SET_LOADING'
  payload: boolean
}

interface SetSplitPacksStateAction {
  type: 'SET_SPLIT_PACKS_STATE'
  payload: {
    adjustedPacks: number
    adjustedUnits: number
    warning: string
  }
}

interface SetInputValuesErrorAction {
  type: 'SET_INPUT_VALUES_ERROR'
  payload: {
    formErrors: {
      adjustedPacks: string
      adjustedUnits: string
      adjustmentReason: string
    }
    error: string
  }
}

type AdjustmentFormActions =
  | SetPacksActionb
  | SetUnitsAction
  | SetReasonAction
  | SetFormErrorsAction
  | SetErrorAction
  | SetExpandedAction
  | SetWarningAction
  | SetLoadingAction
  | SetSplitPacksStateAction
  | SetInputValuesErrorAction

const reducer = (
  state: AdjustmentFormState,
  action: AdjustmentFormActions,
  selectedProductDetails: ProductCurrentStockItem
) => {
  let nextState: AdjustmentFormState = state
  switch (action.type) {
    case 'SET_PACKS':
      nextState = { ...state, adjustedPacks: action.payload }
      break
    case 'SET_UNITS':
      nextState = { ...state, adjustedUnits: action.payload }
      break
    case 'SET_REASON':
      nextState = {
        ...state,
        adjustmentReason: action.payload,
        textFieldVisible: Boolean(action.payload),
      }
      break
    case 'SET_FORM_ERRORS':
      nextState = {
        ...state,
        errors: {
          adjustedPacks: action.payload.adjustedPacks,
          adjustedUnits: action.payload.adjustedUnits,
          adjustmentReason: action.payload.adjustmentReason,
        },
      }
      break
    case 'SET_ERROR':
      nextState = { ...state, error: action.payload, loading: false }
      break
    case 'SET_EXPANDED':
      nextState = { ...state, expanded: action.payload }
      break
    case 'SET_WARNING':
      nextState = { ...state, warning: action.payload }
      break
    case 'SET_LOADING':
      nextState = { ...state, loading: action.payload }
      break
    case 'SET_SPLIT_PACKS_STATE':
      nextState = {
        ...state,
        adjustedPacks: action.payload.adjustedPacks,
        adjustedUnits: action.payload.adjustedUnits,
        warning: action.payload.warning,
      }
      break
    case 'SET_INPUT_VALUES_ERROR':
      nextState = {
        ...state,
        errors: action.payload.formErrors,
        error: action.payload.error,
        loading: false,
      }
      break
  }
  return {
    ...nextState,
    errors: validateForm(
      {
        adjustedPacks: nextState.adjustedPacks,
        adjustedUnits: nextState.adjustedUnits,
        adjustmentReason: nextState.adjustmentReason,
      },
      selectedProductDetails
    ),
  }
}

const reasons = [
  ...AdjustmentReasons[1].reasons,
  ...AdjustmentReasons[2].reasons,
].map((r) => {
  return {
    code: r.code,
    description: reasonsTranslations[r.code],
  }
})

const AdjustmentForm: FC<AdjustmentMenuProps> = ({
  selectedProductDetails,
  onSubmitCallback,
}) => {
  const [state, dispatch] = useReducer(
    (state: AdjustmentFormState, action: AdjustmentFormActions) =>
      reducer(state, action, selectedProductDetails),
    {
      adjustedPacks: 0,
      adjustedUnits: 0,
      adjustmentReason: '',
      errors: validateForm(
        {
          adjustedPacks: 0,
          adjustedUnits: 0,
          adjustmentReason: '',
        },
        selectedProductDetails
      ),
      isSubmitting: false,
      error: '',
      expanded: 'adjust-panel',
      textFieldVisible: false,
      warning: '',
    } as AdjustmentFormState
  )
  const { platformHttpService } = useContext(ServiceContext)
  const selectedClient = useRecoilValue(selectedClientState)

  const handleChangeAccordionSection =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (isExpanded && state.expanded !== panel) {
        dispatch({ type: 'SET_EXPANDED', payload: panel })
      } else if (!isExpanded) {
        dispatch({
          type: 'SET_EXPANDED',
          payload: panel === 'reset-panel' ? 'adjust-panel' : 'reset-panel',
        })
      }
    }

  const handleAdjustFieldBlur = () => {
    dispatch({ type: 'SET_WARNING', payload: '' })
    var currentStockUnits = state.adjustedUnits ?? 0
    let currentStockPacks = state.adjustedPacks ?? 0

    const { currentInsertedUnits, totalNumberOfUnits } =
      CurrentAndTotalNumberOfUnits()
    if (currentInsertedUnits > totalNumberOfUnits) {
      return
    }

    //Perform Calculations
    if (currentStockUnits >= selectedProductDetails.packSize) {
      const calculatedUnits =
        currentStockUnits % selectedProductDetails.packSize
      const calculatedPacks = Math.floor(
        currentStockUnits / selectedProductDetails.packSize
      )

      currentStockPacks = currentStockPacks + calculatedPacks
      dispatch({
        type: 'SET_SPLIT_PACKS_STATE',
        payload: {
          adjustedPacks: currentStockPacks,
          adjustedUnits: calculatedUnits,
          warning: translation.Notifications.NotificationAlreadyCalculatedSplit(
            currentStockUnits,
            calculatedPacks,
            calculatedUnits
          ),
        },
      })
    }
  }

  //current units number inserted and total number from db
  function CurrentAndTotalNumberOfUnits() {
    const currentInsertedUnits =
      (state.adjustedPacks ?? 0) * selectedProductDetails.packSize +
      (state.adjustedUnits ?? 0)
    const totalNumberOfUnits =
      selectedProductDetails.stockPacks * selectedProductDetails.packSize +
      selectedProductDetails.currentStockUnits
    return { currentInsertedUnits, totalNumberOfUnits }
  }

  // When the Adjustment Reason is selected, show the TextField
  const handleSelectionChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value
    dispatch({ type: 'SET_REASON', payload: value })
  }

  // On Submit send requests needed for creating an Adjustemnt
  // It will be only DISPOSALS type Adjustemnts
  const handleSubmit = async (formValues: FormValues) => {
    if (!selectedProductDetails) {
      return
    }
    dispatch({ type: 'SET_LOADING', payload: true })
    //Case - user inserts packs and units more than the total  units from db
    const { currentInsertedUnits, totalNumberOfUnits } =
      CurrentAndTotalNumberOfUnits()
    if (formValues.adjustmentReason !== AdjustmentReasonCodes.StockComingIn) {
      if (currentInsertedUnits > totalNumberOfUnits) {
        dispatch({
          type: 'SET_INPUT_VALUES_ERROR',
          payload: {
            formErrors: {
              ...state.errors,
              adjustedPacks:
                translation.Validation
                  .AdjustedPacksOrUnitsValidationBiggerThanTotalNumber,
              adjustedUnits:
                translation.Validation
                  .AdjustedPacksOrUnitsValidationBiggerThanTotalNumber,
            },
            error:
              translation.Validation.AdjustedPacksOrUnitsValidationBiggerThanTotalNumberAlert(
                totalNumberOfUnits
              ),
          },
        })
        return
      }
    }

    const body: ProductStockUpdateRequest = {
      productId: selectedProductDetails.productId,
      packSize: selectedProductDetails.packSize,
      actionType: 'ADJUSTMENT',
      adjustmentReasonCode: formValues.adjustmentReason,
      packsQuantity: formValues.adjustedPacks ?? 0,
      unitsQuantity: formValues.adjustedUnits ?? 0,
    }
    const response = await platformHttpService.postAsync(
      PlatformApiPaths.AdjustProductStock(selectedClient?.clientId!),
      body,
      'StockBaseUrl'
    )
    if (!response.hasErrors) {
      onSubmitCallback()
      dispatch({ type: 'SET_LOADING', payload: false })
    } else {
      dispatch({
        type: 'SET_ERROR',
        payload: GetErrorMessage(response.statusCode),
      })
    }
  }

  const getHelperText = (errorKey: string | null) => {
    switch (errorKey) {
      case 'NUMBERS_ONLY':
        return translation.Validation.AdjustedPacksOrUnitsValidationInteger
      case 'INTEGER':
        return translation.Validation.AdjustedPacksOrUnitsValidationInteger
      case 'REQUIRED':
        return translation.Validation.AdjustedPacksOrUnitsValidationRequired
      case 'MIN':
        return translation.Validation.AdjustedPacksOrUnitsValidationMin
      case 'MAX':
        return translation.Validation.AdjustedPacksValidationMax
      case 'LESS_THAN_PACK_SIZE':
        return translation.Validation.AdjustedUnitsValidationMax
      default:
        return null
    }
  }

  return (
    <Box sx={{ paddingBottom: theme.spacing(2) }}>
      <Typography variant="h6" sx={{ fontSize: '1.2em' }} align="center">
        {translation.ProductTitle(
          selectedProductDetails!.productName!,
          selectedProductDetails!.packSize!,
          selectedProductDetails!.unitOfMeasure,
          selectedProductDetails!.subPackDescription
        )}
      </Typography>
      <Typography
        variant="h5"
        sx={{ paddingBottom: theme.spacing(2), fontSize: '1em' }}
        align="center"
      >
        <Markdown isInline={true}>
          {translation.ProductDescription(
            selectedProductDetails!.stockPacks!,
            selectedProductDetails!.splitPacksUnits!
          )}
        </Markdown>
      </Typography>
      <Accordion
        disableGutters={true}
        elevation={0}
        expanded={
          !selectedProductDetails.hasBeenReset ||
          state.expanded === 'reset-panel'
        }
        onChange={handleChangeAccordionSection('reset-panel')}
        sx={{
          '&.Mui-expanded': {
            marginBottom: theme.spacing(2),
          },
          '&:before': {
            display: 'none',
          },
          marginBottom: theme.spacing(2),
          background:
            state.expanded === 'reset-panel'
              ? theme.palette.grey[50]
              : theme.palette.common.white,
          borderRadius: theme.spacing(0.5),
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                fontSize={14}
                color={theme.palette.primary.main}
              >
                {translation.AccordionReset}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                fontSize={12}
                color={theme.palette.common.black}
                alignItems="center"
                justifyContent="flex-start"
              >
                {translation.AccordionDescriptionReset}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingX: theme.spacing(1) }}>
          <InputStockForm
            product={selectedProductDetails}
            onSubmitCallback={onSubmitCallback}
            isReset={true}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        disableGutters={true}
        expanded={
          selectedProductDetails.hasBeenReset &&
          state.expanded === 'adjust-panel'
        }
        onChange={handleChangeAccordionSection('adjust-panel')}
        defaultExpanded={true}
        sx={{
          '&.Mui-expanded': {
            marginBottom: theme.spacing(2),
          },
          '&:before': {
            display: 'none',
          },
          marginBottom: theme.spacing(2),
          opacity: !selectedProductDetails.hasBeenReset ? 0.5 : 1,
          background:
            state.expanded === 'adjust-panel'
              ? theme.palette.grey[50]
              : theme.palette.common.white,
          borderRadius: theme.spacing(0.5),
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                fontSize={14}
                color={theme.palette.primary.main}
              >
                {translation.AccordionAdjust}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                fontSize={12}
                color={theme.palette.common.black}
                alignItems="center"
                justifyContent="flex-start"
              >
                {translation.AccordionDescription}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingX: theme.spacing(3),
          }}
        >
          <Box
            sx={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '5px',
              justifyContent: 'center',
              maxHeight: '150x',
              maxWidth: '500px',
              gap: theme.spacing(2),
              '&:focus-visible': { outline: 'none' },
              boxShadow: 'none',
            }}
            component={'form'}
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit({
                adjustedPacks: state.adjustedPacks,
                adjustedUnits: state.adjustedUnits,
                adjustmentReason: state.adjustmentReason,
              } as FormValues)
            }}
          >
            <FormControl>
              <InputLabel id="select-adjustment-form-label">
                {translation.Fields.SelectAdjustmentReason.Label}
              </InputLabel>
              <Select
                value={state.adjustmentReason}
                onChange={(e) => {
                  handleSelectionChange(e)
                }}
                error={Boolean(state.errors.adjustmentReason)}
                data-testid="disposal-or-interbranch-reason-select"
                labelId="select-adjustment-form-label"
                label={translation.Fields.SelectAdjustmentReason.Label}
              >
                {reasons.map((cr) => (
                  <MenuItem key={cr.code} value={cr.code}>
                    {cr.description}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2">
                {state.errors.adjustmentReason}
              </Typography>
            </FormControl>
            {state.textFieldVisible && (
              <>
                <TextField
                  data-testid="adjust-packs-input-field"
                  id="adjustedPacks"
                  name="adjustedPacks"
                  value={state.adjustedPacks}
                  label={
                    state.adjustmentReason ===
                    AdjustmentReasonCodes.StockComingIn
                      ? translation.Fields.AdjustedPacks.Label(false)
                      : translation.Fields.AdjustedPacks.Label(true)
                  }
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_PACKS',
                      payload:
                        e.target.value === ''
                          ? undefined
                          : parseInt(e.target.value, 10),
                    })
                  }
                  onBlur={handleAdjustFieldBlur}
                  error={Boolean(state.errors.adjustedPacks)}
                  helperText={getHelperText(state.errors.adjustedPacks)}
                  type="number"
                  sx={{ textAlign: 'center' }}
                />
                <TextField
                  data-testid="adjust-units-input-field"
                  id="adjustedUnits"
                  name="adjustedUnits"
                  value={state.adjustedUnits}
                  label={
                    state.adjustmentReason ===
                    AdjustmentReasonCodes.StockComingIn
                      ? translation.Fields.AdjustedUnits.Label(false)
                      : translation.Fields.AdjustedUnits.Label(true)
                  }
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_UNITS',
                      payload:
                        e.target.value === ''
                          ? undefined
                          : parseInt(e.target.value, 10),
                    })
                  }
                  onBlur={handleAdjustFieldBlur}
                  error={Boolean(state.errors.adjustedUnits)}
                  helperText={getHelperText(state.errors.adjustedUnits)}
                  type="number"
                  sx={{ textAlign: 'center' }}
                />
              </>
            )}
            <LoadingButton
              data-testid="complete-adjusting-stock-button"
              type="submit"
              disableElevation
              variant="contained"
              color="primary"
              loading={state.loading}
              disabled={
                Boolean(state.errors.adjustedPacks) ||
                Boolean(state.errors.adjustedUnits) ||
                Boolean(state.errors.adjustmentReason)
              }
              sx={{
                minWidth: '160px',
              }}
            >
              {translation.Buttons.Submit(
                state.adjustedPacks,
                state.adjustedUnits
              )}
            </LoadingButton>
            {state.warning && (
              <Alert variant="filled" severity="warning">
                {state.warning}
              </Alert>
            )}
            {state.error && (
              <Alert variant="filled" severity="error">
                {state.error}
              </Alert>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default AdjustmentForm
