import { FC, useReducer } from 'react'
import theme from '../../../styles/theme'
import { SxProps, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { locale } from '../../../locales'
import * as yup from 'yup'
import EditableSection from './EditableSection'
import { NewUserAuthId } from '../../../constants'

const editFieldTextFieldStyle: SxProps<Theme> = {
  '& .MuiInputBase-input.Mui-disabled': {
    backgroundColor: theme.palette.grey[50],
    WebkitTextFillColor: theme.palette.grey[800],
  },
}

const translation =
  locale.translation.SettingsPage.UserManagementTab.UserManagement.UserDetails

const validationSchema = (isUserUnique: (email: string) => boolean) =>
  yup.object().shape({
    email: yup
      .string()
      .email('EMAIL_REQUIRED')
      .required('EMAIL_REQUIRED')
      .test('unique', 'EMAIL_UNIQUE', (value) => {
        return isUserUnique(value || '')
      }),
    fullName: yup
      .string()
      .min(4, 'FULLNAME_REQUIRED')
      .required('FULLNAME_REQUIRED'),
  })

export const validateUserDetailsState = (
  state: UserDetailsState,
  isUserUnique: (email: string) => boolean
): { email: string; fullName: string } => {
  try {
    validationSchema(isUserUnique).validateSync(state, { abortEarly: false })
    return { email: '', fullName: '' }
  } catch (err: any) {
    const errors = { email: '', fullName: '' }
    err.inner.forEach((validationError: any) => {
      if (validationError.path === 'email') {
        errors.email = validationError.message
      } else if (validationError.path === 'fullName') {
        errors.fullName = validationError.message
      }
    })
    return errors
  }
}

interface UserDetailsState {
  email: string
  fullName: string
  errors: {
    email: string
    fullName: string
  }
  isEditing: boolean
}

interface SetEmailAction {
  type: 'SET_EMAIL'
  payload: string
}

interface SetFullNameAction {
  type: 'SET_FULLNAME'
  payload: string
}

interface SetErrorsAction {
  type: 'SET_ERRORS'
  payload: {
    email: string
    fullName: string
  }
}

interface ResetFormAction {
  type: 'RESET_FORM'
  payload: {
    email: string
    fullName: string
  }
}

interface SetEditingAction {
  type: 'SET_EDITING'
  payload: boolean
}

type UserDetailsActions =
  | SetEmailAction
  | SetFullNameAction
  | SetErrorsAction
  | ResetFormAction
  | SetEditingAction

const reducer = (
  state: UserDetailsState,
  isUserUnique: (email: string) => boolean,
  action: UserDetailsActions
) => {
  let nextState: UserDetailsState = state
  switch (action.type) {
    case 'SET_EMAIL':
      nextState = { ...state, email: action.payload }
      break
    case 'SET_FULLNAME':
      nextState = { ...state, fullName: action.payload }
      break
    case 'SET_ERRORS':
      nextState = { ...state, errors: action.payload }
      break
    case 'RESET_FORM':
      nextState = {
        ...state,
        email: action.payload.email,
        fullName: action.payload.fullName,
        errors: {
          email: '',
          fullName: '',
        },
      }
      break
    case 'SET_EDITING':
      nextState = { ...state, isEditing: action.payload }
      break
  }
  return {
    ...nextState,
    errors: validateUserDetailsState(nextState, isUserUnique),
  }
}

const UserDetails: FC<{
  authId: string
  email: string
  fullName: string
  onUpdatedDetails: (authId: string, email: string, fullName: string) => void
  onCancel?: () => void
  autoEdit?: boolean
  isUserUnique: (userName: string) => boolean
}> = (props) => {
  const [state, dispatch] = useReducer(
    (state: UserDetailsState, action: UserDetailsActions) =>
      reducer(state, props.isUserUnique, action),
    {
      email: props.email,
      fullName: props.fullName,
      errors: validateUserDetailsState(
        {
          email: props.email,
          fullName: props.fullName,
          errors: { email: '', fullName: '' },
          isEditing: Boolean(props.autoEdit),
        },
        props.isUserUnique
      ),
      isEditing: Boolean(props.autoEdit),
    } as UserDetailsState
  )

  return (
    <Box>
      <EditableSection
        onCancel={() => {
          if (props.onCancel) {
            props.onCancel()
          } else {
            dispatch({
              type: 'RESET_FORM',
              payload: { email: props.email, fullName: props.fullName },
            })
          }
        }}
        onUpdate={() => {
          props.onUpdatedDetails(props.authId, state.email, state.fullName)
        }}
        sectionTitle={translation.UserDetails}
        updateDisabled={Boolean(state.errors.email || state.errors.fullName)}
        onEditingChanged={(editing) =>
          dispatch({ type: 'SET_EDITING', payload: editing })
        }
        titleSx={{ fontWeight: 600 }}
        autoEdit={Boolean(props.autoEdit)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            paddingY: theme.spacing(1),
          }}
        >
          <TextField
            sx={{ ...editFieldTextFieldStyle }}
            autoComplete="off"
            label={translation.Email}
            variant="outlined"
            value={state.email}
            onChange={(e) => {
              dispatch({ type: 'SET_EMAIL', payload: e.target.value })
            }}
            disabled={!state.isEditing || props.authId !== NewUserAuthId}
            error={Boolean(state.errors.email)}
            helperText={
              state.errors.email === 'EMAIL_REQUIRED'
                ? translation.ErrorEmailRequired
                : state.errors.email === 'EMAIL_UNIQUE'
                ? translation.ErrorEmailNotUnique
                : null
            }
            type="email"
          />
          <TextField
            sx={{ ...editFieldTextFieldStyle }}
            autoComplete="off"
            label={translation.FullName}
            variant="outlined"
            value={state.fullName}
            onChange={(e) => {
              dispatch({ type: 'SET_FULLNAME', payload: e.target.value })
            }}
            disabled={!state.isEditing}
            error={Boolean(state.errors.fullName)}
            helperText={
              state.errors.fullName === 'FULLNAME_REQUIRED'
                ? translation.ErrorFullNameRequired
                : null
            }
          />
        </Box>
      </EditableSection>
    </Box>
  )
}

export default UserDetails
