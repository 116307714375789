import { validateUniqueInArray } from '../../utils/Helpers'
import * as yup from 'yup'
import InputSectionWithTags, {
  InputSectionWithTagsHandle,
} from './InputSectionWithTags'
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { locale } from '../../locales'

const translations = locale.translation.Common

const validationSchema = yup
  .string()
  .email('emailAddress')
  .test('duplicateEmail', 'duplicateEmail', function (value) {
    if (!value) return true
    const { existingEmails } = this.options.context as {
      existingEmails: string[]
    }
    return validateUniqueInArray(existingEmails, value)
  })

export interface MultiEmailInputProps {
  emails: string[]
  label: string
  isDisabled: boolean
  onEmailsChanged?: (emails: string[]) => void
  onErrorsChanged: (hasError: boolean) => void
}

export interface MultiEmailInputHandle {
  getTags: () => string[]
  clearValue: () => void
}

const MultiEmailInput: ForwardRefRenderFunction<
  MultiEmailInputHandle,
  MultiEmailInputProps
> = ({ onErrorsChanged, ...props }, forwardedRef) => {
  const [errorKey, setErrorKey] = useState<string>('')
  const inputSectionWithTagsHandle = useRef<InputSectionWithTagsHandle>(null)

  useImperativeHandle(forwardedRef, () => ({
    clearValue: () => {
      inputSectionWithTagsHandle.current?.clearValue()
      setErrorKey('')
    },
    getTags: () => inputSectionWithTagsHandle.current?.getTags() ?? [],
  }))

  const handleValidateTags = useCallback(
    (tags: string[]) => {
      const errors = tags.map((tag) => {
        try {
          validationSchema.validateSync(tag, {
            context: {
              existingEmails: tags,
            },
          })
          return false
        } catch (error: any) {
          setErrorKey(error.errors[error.errors.length - 1])
          return true
        }
      })
      onErrorsChanged(errors.includes(true))
      if (!errors.includes(true)) {
        setErrorKey('')
      }
      return errors
    },
    [onErrorsChanged]
  )

  return (
    <InputSectionWithTags
      ref={inputSectionWithTagsHandle}
      label={props.label}
      tags={props.emails}
      validateTags={handleValidateTags}
      isDisabled={props.isDisabled}
      errorMessage={
        errorKey?.includes('emailAddress')
          ? translations.ErrorEmailRequired
          : errorKey?.includes('duplicateEmail')
          ? translations.ErrorDuplicateEmail
          : null
      }
    />
  )
}

export default forwardRef(MultiEmailInput)
