import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react'
import { ProductItemWithAdjustmentAndInvoiceDetails } from '../../../types/entities/Credit'
import Typography from '@mui/material/Typography'
import { locale } from '../../../locales'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import LoadingButton from '@mui/lab/LoadingButton'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import {
  AdjustmentStatuses,
  PackSize,
  ErrorMatchingCreditForAction,
} from '../../../constants'
import theme from '../../../styles/theme'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import {
  getLocaleDateMedWithoutWeekDay,
  getLocaleDateTimeMedWithoutWeekDay,
  toCurrencyString,
} from '../../../utils/Helpers'
import { SxProps, Theme } from '@mui/material/styles'
import { AdjustmentSummaryWithImage } from '../entities/BookIn'

interface AdjustmentMenuProps {
  selectedProductDetails: ProductItemWithAdjustmentAndInvoiceDetails
  onSubmitCallback: (
    adjustmentId: string,
    status: string,
    notes: string
  ) => void
}
const translation =
  locale.translation.GoodsInPage.TabCreditsForAction.CreditForActionForm
const reasons = {
  ...locale.translation.DisposalReasons,
  ...locale.translation.CreditReasons,
}

interface CreditForActionFormState {
  isReceived: boolean
  isActioned: boolean
  isRejected: boolean
  notes: string | null
  error: string
  loading: boolean
  photo: string
  formHasChanged: boolean
}

interface SetIsReceivedAction {
  type: 'SET_IS_RECEIVED'
  payload: boolean
}

interface SetIsActionedAction {
  type: 'SET_IS_ACTIONED'
  payload: boolean
}

interface SetIsRejectedAction {
  type: 'SET_IS_REJECTED'
  payload: boolean
}

interface SetNotesAction {
  type: 'SET_NOTES'
  payload: string
}

interface SetErrorAction {
  type: 'SET_ERROR'
  payload: string
}

interface SetLoadingAction {
  type: 'SET_LOADING'
  payload: boolean
}

interface SetFormHasChangedAction {
  type: 'SET_FORM_HAS_CHANGED'
  payload: boolean
}

interface ResetFormAction {
  type: 'RESET_FORM'
  payload: {
    isReceived: boolean
    isActioned: boolean
    isRejected: boolean
    notes: string
    photo: string
  }
}

type CreditForActionFormAction =
  | SetIsReceivedAction
  | SetIsActionedAction
  | SetIsRejectedAction
  | SetNotesAction
  | SetErrorAction
  | SetLoadingAction
  | SetFormHasChangedAction
  | ResetFormAction

const reducer = (
  state: CreditForActionFormState,
  action: CreditForActionFormAction
): CreditForActionFormState => {
  switch (action.type) {
    case 'SET_IS_RECEIVED':
      return { ...state, isReceived: action.payload }
    case 'SET_IS_ACTIONED':
      return { ...state, isActioned: action.payload }
    case 'SET_IS_REJECTED':
      return { ...state, isRejected: action.payload }
    case 'SET_NOTES':
      return { ...state, notes: action.payload }
    case 'SET_ERROR':
      return { ...state, error: action.payload, loading: false }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    case 'SET_FORM_HAS_CHANGED':
      return { ...state, formHasChanged: action.payload }
    case 'RESET_FORM':
      return {
        ...state,
        isReceived: action.payload.isReceived,
        isActioned: action.payload.isActioned,
        isRejected: action.payload.isRejected,
        notes: action.payload.notes,
        photo: action.payload.photo,
        error: '',
        loading: false,
        formHasChanged: false,
      }
    default:
      return state
  }
}

export const CreditForActionForm: FC<AdjustmentMenuProps> = ({
  selectedProductDetails,
  onSubmitCallback,
}) => {
  const { platformHttpService, notificationService } =
    useContext(ServiceContext)

  const [state, dispatch] = useReducer(reducer, {
    isReceived: selectedProductDetails.received!,
    isActioned: selectedProductDetails.actioned!,
    isRejected: selectedProductDetails.rejected!,
    notes: '',
    error: '',
    loading: false,
    photo: '',
    formHasChanged: false,
  } as CreditForActionFormState)

  const handleSubmit = async () => {
    const status =
      state.isReceived === true
        ? AdjustmentStatuses.Received
        : state.isRejected === true
        ? AdjustmentStatuses.Rejected
        : state.isActioned === true
        ? AdjustmentStatuses.Actioned
        : AdjustmentStatuses.Completed

    dispatch({ type: 'SET_LOADING', payload: true })

    // Update adjustment status
    if (
      state.isActioned !== selectedProductDetails.actioned ||
      state.isReceived !== selectedProductDetails.received ||
      state.isRejected !== selectedProductDetails.rejected
    ) {
      const responseActioned = await platformHttpService.postAsync<string>(
        PlatformApiPaths.SetAdjustmentActioned(selectedProductDetails.odsCode!),
        {
          AdjustmentId: selectedProductDetails.adjustmentId,
          ActionedStatus: status,
        },
        'StockBaseUrl'
      )
      if (responseActioned.hasErrors) {
        if (
          responseActioned.errors[0].message.includes(
            ErrorMatchingCreditForAction.adjustmentError
          )
        ) {
          dispatch({
            type: 'SET_ERROR',
            payload: GetErrorMessage(
              400,
              translation.Notifications.ErrorAlertIfStatusNotValid
            ),
          })
        } else {
          dispatch({
            type: 'SET_ERROR',
            payload: GetErrorMessage(responseActioned.statusCode),
          })
        }
        return
      }
    }

    if (!selectedProductDetails.hasNotes && !Boolean(state.notes)) {
      // Notes haven't changed so go back
      dispatch({ type: 'SET_LOADING', payload: false })
      onSubmitCallback(
        selectedProductDetails.adjustmentId!,
        status,
        state?.notes ?? ''
      )
    } else {
      // Update notes
      const responseNotes = await platformHttpService.postAsync<string>(
        PlatformApiPaths.SetAdjustmentNotes(selectedProductDetails.odsCode!),
        {
          AdjustmentId: selectedProductDetails.adjustmentId,
          Notes: state.notes,
        },
        'StockBaseUrl'
      )
      dispatch({ type: 'SET_LOADING', payload: false })

      if (!responseNotes.hasErrors) {
        notificationService.showNotification(
          translation.Notifications.NotificationCreditUpdatedsuccessfully,
          'success',
          5000
        )
        onSubmitCallback(
          selectedProductDetails.adjustmentId!,
          status,
          state?.notes ?? ''
        )
      } else {
        if (
          responseNotes.errors[0].message.includes(
            ErrorMatchingCreditForAction.enrichLimitError
          )
        ) {
          dispatch({
            type: 'SET_ERROR',
            payload: GetErrorMessage(
              400,
              translation.Notifications.ErrorAlertUserEnrichesMaximumCharacters
            ),
          })
        } else {
          dispatch({
            type: 'SET_ERROR',
            payload: GetErrorMessage(responseNotes.statusCode),
          })
        }
      }
    }
  }

  const getInitialValues = useCallback(
    (notes?: string, photo?: string) => {
      return {
        isReceived: selectedProductDetails.received!,
        isActioned: selectedProductDetails.actioned!,
        isRejected: selectedProductDetails.rejected!,
        notes: notes ?? '',
        photo: photo ?? '',
      }
    },
    [selectedProductDetails]
  )

  useEffect(() => {
    const getAdjustment = async (odsCode: string, adjustmentId: string) => {
      return await platformHttpService.getAsync<AdjustmentSummaryWithImage>(
        PlatformApiPaths.GetAdjustmentById(odsCode, adjustmentId),
        'StockBaseUrl'
      )
    }
    if (
      selectedProductDetails?.odsCode &&
      selectedProductDetails?.adjustmentId
    ) {
      if (selectedProductDetails.hasNotes || selectedProductDetails.hasPhoto) {
        getAdjustment(
          selectedProductDetails.odsCode,
          selectedProductDetails.adjustmentId
        ).then((response) => {
          dispatch({
            type: 'RESET_FORM',
            payload: getInitialValues(
              response?.data?.notes,
              response?.data?.photoUrl ?? ''
            ),
          })
        })
      }
    }
  }, [
    getInitialValues,
    platformHttpService,
    selectedProductDetails.adjustmentId,
    selectedProductDetails.hasNotes,
    selectedProductDetails.hasPhoto,
    selectedProductDetails.odsCode,
  ])

  const handleFormChange = () => {
    dispatch({ type: 'SET_FORM_HAS_CHANGED', payload: true })
  }

  const rowStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }

  return (
    <>
      <FormControl
        component={'form'}
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
        onChange={handleFormChange}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h5">
          {`${selectedProductDetails.productName} [${
            selectedProductDetails.productPackSize === -1
              ? PackSize.Specials
              : selectedProductDetails.productPackSize
          }]`}
        </Typography>
        <Typography sx={{ color: theme.palette.grey[500] }}>
          {translation.LabelAdjustmentDate}
          <Box component="span" sx={{ fontWeight: 600 }}>
            {getLocaleDateTimeMedWithoutWeekDay(
              selectedProductDetails!.adjustmentDateTime!
            ) ?? ''}
          </Box>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              paddingRight: theme.spacing(2),
            }}
          >
            <Box sx={{ ...rowStyle }}>
              <Typography>
                {translation.LabelSupplier}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {selectedProductDetails.supplierDisplayName}
                </Box>
              </Typography>
              <Typography>
                {translation.LabelInvoiceNumber}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {selectedProductDetails.invoiceNumber}
                </Box>
              </Typography>
            </Box>
            <Box sx={rowStyle}>
              <Typography>
                {translation.LabelInvoiceDate}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {getLocaleDateMedWithoutWeekDay(
                    selectedProductDetails!.invoiceDate!
                  ) ?? ''}
                </Box>
              </Typography>
              <Typography>
                {translation.LabelProductCode}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {selectedProductDetails.productCode
                    ? selectedProductDetails.productCode
                    : '-'}
                </Box>
              </Typography>
            </Box>
            <Box sx={{ ...rowStyle, marginTop: theme.spacing(3) }}>
              <Typography>
                {translation.LabelCreditPacks}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {selectedProductDetails.adjustmentNumberOfPacks}
                </Box>
              </Typography>
              <Typography>
                {translation.LabelCreditReason}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {reasons[selectedProductDetails.adjustmentReasonCode!]}
                </Box>
              </Typography>
              <Typography>
                {translation.LabelPricePerUnit}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {toCurrencyString(selectedProductDetails.productUnitPrice)}
                </Box>
              </Typography>
              <Typography>
                {translation.LabelTotalCredit}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {toCurrencyString(selectedProductDetails.totalCreditValue)}
                </Box>
              </Typography>
            </Box>
          </Box>
          {selectedProductDetails.hasPhoto && (
            <Box sx={{ width: '120px' }}>
              <img src={state.photo} alt="credit" style={{ width: '100%' }} />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: theme.spacing(1),
            margin: theme.spacing(2),
            marginX: 0,
            fontWeight: 600,
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: '4px',
          }}
        >
          <FormControlLabel
            id="actioned-status-checkbox"
            control={
              <Checkbox
                checked={state.isActioned}
                disabled={state.isReceived || state.isRejected}
                onChange={(e) =>
                  dispatch({
                    type: 'SET_IS_ACTIONED',
                    payload: e.target.checked,
                  })
                }
                name="isActioned"
              />
            }
            label={translation.LabelActionedBasedOnStatus}
          />
          <FormControlLabel
            id="received-status-checkbox"
            control={
              <Checkbox
                name="isReceived"
                checked={state.isReceived}
                disabled={!state.isActioned || state.isRejected}
                onChange={(e) =>
                  dispatch({
                    type: 'SET_IS_RECEIVED',
                    payload: e.target.checked,
                  })
                }
              />
            }
            label={translation.LabelReceived}
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          />
          <FormControlLabel
            id="rejected-status-checkbox"
            control={
              <Checkbox
                name="isRejected"
                checked={state.isRejected}
                disabled={!state.isActioned || state.isReceived}
                onChange={(e) =>
                  dispatch({
                    type: 'SET_IS_REJECTED',
                    payload: e.target.checked,
                  })
                }
              />
            }
            label={translation.LabelRejected}
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          />
        </Box>
        <TextField
          data-testid="credits-text-area"
          id="multiline-notes-input"
          label={translation.LabelNotes}
          multiline
          fullWidth
          rows={5}
          value={state.notes}
          name="notes"
          onChange={(e) =>
            dispatch({ type: 'SET_NOTES', payload: e.target.value })
          }
          sx={{ marginBottom: theme.spacing(2) }}
        />
        <LoadingButton
          data-testid="complete-save-receive-notes-button"
          type="submit"
          disableElevation
          variant="contained"
          color="primary"
          loading={state.loading}
          disabled={!state.formHasChanged || Boolean(state.error)}
        >
          {translation.SaveButton}
        </LoadingButton>
      </FormControl>

      {state.error && (
        <Box
          sx={{
            marginTop: theme.spacing(1),
          }}
        >
          <Alert variant="filled" severity="error">
            {state.error}
          </Alert>
        </Box>
      )}
    </>
  )
}
