import { FC, useEffect, useMemo, useReducer } from 'react'
import { useSuppliersUniquePerId } from '../../../hooks/useSuppliers'
import Box from '@mui/material/Box/Box'
import TextField from '@mui/material/TextField/TextField'
import FormControl from '@mui/material/FormControl/FormControl'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import Select from '@mui/material/Select/Select'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import { locale } from '../../../locales'
import theme from '../../../styles/theme'
import Button from '@mui/material/Button/Button'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
const translations =
  locale.translation.SettingsPage.PharmacySettingsTab
    .SupplierAccountMappingContainer

interface SupplierAccounIdMappingSectionState {
  supplierAccountMaps: { [key: string]: string }
  supplierIdValidationErrors?: { [key: string]: string } | null
  isAddingNewSupplier: boolean
}

interface SetSupplierAccountMapsAction {
  type: 'SET_SUPPLIER_ACCOUNT_MAPS'
  payload: { [key: string]: string }
}

interface SetSupplierIdValidationErrorsAction {
  type: 'SET_SUPPLIER_ID_VALIDATION_ERRORS'
  payload: { [key: string]: string }
}

interface SetIsAddingNewSupplierAction {
  type: 'SET_IS_ADDING_NEW_SUPPLIER'
  payload: boolean
}
interface ClearValidationErrorsAction {
  type: 'CLEAR_VALIDATION_ERRORS'
  payload: { [key: string]: string }
}
type SupplierAccounIdMappingSectionAction =
  | SetSupplierAccountMapsAction
  | SetSupplierIdValidationErrorsAction
  | SetIsAddingNewSupplierAction
  | ClearValidationErrorsAction

const reducer = (
  state: SupplierAccounIdMappingSectionState,
  action: SupplierAccounIdMappingSectionAction
) => {
  let nextState: SupplierAccounIdMappingSectionState = state
  switch (action.type) {
    case 'SET_SUPPLIER_ACCOUNT_MAPS':
      nextState = {
        ...state,
        supplierAccountMaps: action.payload,
      }
      break
    case 'SET_SUPPLIER_ID_VALIDATION_ERRORS':
      nextState = {
        ...state,
        supplierIdValidationErrors: action.payload,
      }
      break
    case 'SET_IS_ADDING_NEW_SUPPLIER':
      nextState = {
        ...state,
        isAddingNewSupplier: action.payload,
      }
      break
    case 'CLEAR_VALIDATION_ERRORS':
      nextState = {
        ...state,
        supplierIdValidationErrors: null,
        supplierAccountMaps: action.payload,
      }
      break
  }
  return nextState
}

const SupplierAccounIdMappingSection: FC<{
  supplierAccountMaps?: { [key: string]: string }
  clientInternalSuppliers?: { [key: string]: string }
  onSupplierAccountMapChange: (updatedSupplierAccountMaps: {
    [key: string]: string
  }) => void
  onValidationChanged: (isValid: boolean) => void
  isEditingSupplierAccountIds: boolean
  supplierIdsWithValidationErrors?: string[] | null
}> = (props) => {
  const { suppliersIdDisplayNameDictionary } = useSuppliersUniquePerId()

  const [state, dispatch] = useReducer(reducer, {
    supplierAccountMaps: props.supplierAccountMaps ?? {},
    isAddingNewSupplier: false,
  })

  useEffect(() => {
    const errors: { [key: string]: string } = {}

    if (!props.isEditingSupplierAccountIds) {
      dispatch({
        type: 'CLEAR_VALIDATION_ERRORS',
        payload: props.supplierAccountMaps ?? {},
      })
      props.onValidationChanged(false)
    }

    // set unique validation errors
    if (props.supplierIdsWithValidationErrors) {
      props.supplierIdsWithValidationErrors.forEach((id) => {
        errors[id] = translations.AccountIdNotUnique
      })
    }
    // set empty validation errors
    Object.keys(state.supplierAccountMaps).forEach((key) => {
      if (!state.supplierAccountMaps[key]) {
        errors[key] = translations.AccountIdEmptyError
        props.onValidationChanged(true)
      } else {
        props.onValidationChanged(false)
      }
    })
    dispatch({
      type: 'SET_SUPPLIER_ID_VALIDATION_ERRORS',
      payload: errors,
    })
  }, [
    props.supplierAccountMaps,
    props.isEditingSupplierAccountIds,
    props,
    state.supplierAccountMaps,
  ])

  const allSuppliersInSystem = useMemo(() => {
    return Object.keys(suppliersIdDisplayNameDictionary)
      .map((k) => {
        return {
          id: k,
          name: suppliersIdDisplayNameDictionary[k],
        }
      })
      .concat(
        props.clientInternalSuppliers
          ? Object.keys(props.clientInternalSuppliers).map((k) => {
              return {
                id: k,
                name: props.clientInternalSuppliers![k],
              }
            })
          : []
      )
  }, [props.clientInternalSuppliers, suppliersIdDisplayNameDictionary])

  const availableSupplers = useMemo(() => {
    return allSuppliersInSystem.filter(
      (s) => !Object.keys(state.supplierAccountMaps).includes(s.id)
    )
  }, [allSuppliersInSystem, state.supplierAccountMaps])

  const handleAddSupplierMap = (key: string, value: string) => {
    dispatch({
      type: 'SET_SUPPLIER_ACCOUNT_MAPS',
      payload: {
        ...state.supplierAccountMaps,
        [key]: value,
      },
    })
    props.onSupplierAccountMapChange({
      ...state.supplierAccountMaps,
      [key]: value,
    })
    props.onValidationChanged(false)
  }

  const handleRemoveSupplierMap = (key: string) => {
    const filteredSupplierAccountMaps = Object.fromEntries(
      Object.entries(state.supplierAccountMaps).filter(([k]) => k !== key)
    )
    props.onSupplierAccountMapChange(
      filteredSupplierAccountMaps as { [key: string]: string }
    )
    dispatch({
      type: 'SET_SUPPLIER_ACCOUNT_MAPS',
      payload: filteredSupplierAccountMaps,
    })
  }

  const renderNewSupplierMap = () => {
    if (!state.isAddingNewSupplier || !props.isEditingSupplierAccountIds)
      return null

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(5),
        }}
      >
        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel id="select-supplier-label">
            {translations.SelectSupplier}
          </InputLabel>
          <Select
            labelId="select-supplier-label"
            value={''}
            onChange={(e: SelectChangeEvent) => {
              dispatch({
                type: 'SET_SUPPLIER_ACCOUNT_MAPS',
                payload: {
                  ...state.supplierAccountMaps,
                  [e.target.value as string]: '',
                },
              })
              dispatch({
                type: 'SET_IS_ADDING_NEW_SUPPLIER',
                payload: false,
              })
            }}
            label={translations.SelectSupplier}
            disabled={!props.isEditingSupplierAccountIds}
          >
            {availableSupplers.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div
          style={{
            flexGrow: 1,
          }}
        />
        <div
          style={{
            width: '40px',
          }}
        />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        marginTop: theme.spacing(1),
      }}
    >
      {state.supplierAccountMaps &&
        Object.keys(state.supplierAccountMaps).map((k) => {
          const supplier = allSuppliersInSystem.find((s) => s.id === k)
          if (supplier) {
            return (
              <Box
                key={supplier.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: theme.spacing(1),
                }}
              >
                <TextField
                  label={translations.Supplier}
                  value={supplier.name}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                  sx={{ pointerEvents: 'none' }}
                  fullWidth
                  disabled={!props.isEditingSupplierAccountIds}
                />
                <TextField
                  label={translations.AccountId}
                  value={state.supplierAccountMaps[k]}
                  onChange={(e) => {
                    handleAddSupplierMap(k, e.target.value)
                  }}
                  variant="outlined"
                  fullWidth
                  disabled={!props.isEditingSupplierAccountIds}
                  error={Boolean(state.supplierIdValidationErrors?.[k])}
                  helperText={state.supplierIdValidationErrors?.[k]}
                />
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleRemoveSupplierMap(supplier.id as string)}
                  disabled={!props.isEditingSupplierAccountIds}
                  sx={{
                    minWidth: '60px',
                    height: '53px',
                    alignSelf: 'flex-start',
                  }}
                >
                  <CloseIcon sx={{ fontSize: '1.5em' }} />
                </Button>
              </Box>
            )
          }
          return undefined
        })}
      {renderNewSupplierMap()}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(1),
          justifyContent: 'flex-start',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            dispatch({ type: 'SET_IS_ADDING_NEW_SUPPLIER', payload: true })
          }
          disabled={
            !props.isEditingSupplierAccountIds ||
            !Boolean(Object.keys(availableSupplers).length > 0)
          }
        >
          <AddIcon sx={{ fontSize: '1.5em' }} />
        </Button>
      </Box>
    </Box>
  )
}

export default SupplierAccounIdMappingSection
