import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { SxProps, Theme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as yup from 'yup'
import { locale } from '../../locales'
import theme from '../../styles/theme'
import RevealPasswordInput from './RevealPasswordInput'
import { useReducer } from 'react'

const loginValidationSchema = yup.object({
  email: yup.string().required('REQUIRED'),
  password: yup.string().required('REQUIRED'),
})

const validateForm = (values: { email: string; password: string }) => {
  try {
    loginValidationSchema.validateSync(values, { abortEarly: false })
    return { email: '', password: '' }
  } catch (err: any) {
    const errors = {
      email: '',
      password: '',
    }
    if (err.inner) {
      err.inner.forEach((validationError: any) => {
        if (validationError.path === 'email') {
          errors.email = validationError.message
        } else if (validationError.path === 'password') {
          errors.password = validationError.message
        }
      })
    }
    return errors
  }
}

interface LoginFragmentState {
  email: string
  password: string
  errors: {
    email: string
    password: string
  }
  touched: {
    email: boolean
    password: boolean
  }
}

interface SetEmailAction {
  type: 'SET_EMAIL'
  payload: string
}

interface SetPasswordAction {
  type: 'SET_PASSWORD'
  payload: string
}

type LoginFragmentAction = SetEmailAction | SetPasswordAction

const reducer = (
  state: LoginFragmentState,
  action: LoginFragmentAction
): LoginFragmentState => {
  let nextState: LoginFragmentState = state
  switch (action.type) {
    case 'SET_EMAIL':
      nextState = {
        ...state,
        email: action.payload,
        touched: {
          ...state.touched,
          email: true,
        },
      }
      break
    case 'SET_PASSWORD':
      nextState = {
        ...state,
        password: action.payload,
        touched: { ...state.touched, password: true },
      }
      break
  }
  return {
    ...nextState,
    errors: validateForm({
      email: nextState.email,
      password: nextState.password,
    }),
  }
}

export const LogInFragment: React.FC<{
  cardStyle: SxProps<Theme>
  handleSubmit: (username: string, password: string) => void
  handleForgotPassword: () => void
  loginError: string | null
}> = ({ cardStyle, handleSubmit, handleForgotPassword, loginError }) => {
  const [state, dispatch] = useReducer(reducer, {
    email: '',
    password: '',
    errors: {
      email: '',
      password: '',
    },
    touched: {
      email: false,
      password: false,
    },
  })

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit(state.email, state.password)
      }}
      data-testid="login-form"
    >
      <Card variant="outlined" sx={cardStyle}>
        <CardContent sx={{ padding: theme.spacing(1) }}>
          <Stack spacing={2}>
            <Typography variant="h5">
              {locale.translation.LogInForm.Title}
            </Typography>
            <TextField
              fullWidth
              id="email"
              name="email"
              label={locale.translation.LogInForm.Fields.Email.Label}
              placeholder={
                locale.translation.LogInForm.Fields.Email.Placeholder
              }
              value={state.email}
              onChange={(e) =>
                dispatch({ type: 'SET_EMAIL', payload: e.target.value })
              }
              error={state.touched.email && Boolean(state.errors.email)}
              helperText={
                state.errors.email === 'REQUIRED'
                  ? locale.translation.LogInForm.Fields.Email.Validation
                      .Required
                  : null
              }
            />
            <RevealPasswordInput
              error={
                (state.touched.password ?? false) &&
                Boolean(state.errors.password)
              }
              label={locale.translation.LogInForm.Fields.Password.Label}
              placeholder={
                locale.translation.LogInForm.Fields.Password.Placeholder
              }
              value={state.password}
              onChanged={(v) => dispatch({ type: 'SET_PASSWORD', payload: v })}
              helperText={
                state.errors.password === 'REQUIRED'
                  ? locale.translation.LogInForm.Fields.Password.Validation
                      .Required
                  : null
              }
            />
            {loginError && (
              <Alert variant="filled" severity="error">
                {loginError}
              </Alert>
            )}
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disableElevation={true}
          >
            {locale.translation.LogInForm.Buttons.Submit}
          </Button>
          <Button
            variant="text"
            sx={{ textTransform: 'none' }}
            onClick={() => handleForgotPassword()}
          >
            {locale.translation.LogInForm.Buttons.ForgotPassword}
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}
