import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { SxProps, Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { PropsWithChildren, useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { mobileNavigationOpenState } from '../../state/UIStates'
import theme from '../../styles/theme'
import { locale } from '../../locales'
import {
  NavigationPaths,
  OrganistionFeatureFlags,
  PermissionNames,
  PharmacyFeatureFlags,
} from '../../constants'
import Link from '@mui/material/Link'
import Logo from '../Logo'
import { configurationSettings } from '../../configuration/configurationSettings'
import OpenInNew from '@mui/icons-material/OpenInNew'
import { ServiceContext } from '../../providers/ServicesProvider'
import {
  featureFlagsForSelectedClientState,
  userPermissionsForSelectedClientState,
} from '../../state/AuthenticationState'
import { ClientTypes } from '../../types/entities/ClientPermission'

import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined'
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined'
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined'
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined'

const drawerPaperStyle: SxProps<Theme> = {
  width: theme.custom.navigation.width,
  position: 'fixed',
}

const translation = locale.translation.Navigation

export interface NavigationLinkMap {
  location: string
  featureFlag?: string
  availableToClientType?: string
  permission?: string
}

export const navigationLinkMaps: { [key: string]: NavigationLinkMap } = {
  '/analytics': {
    location: NavigationPaths.Analytics,
    featureFlag: PharmacyFeatureFlags.EnableAnalytics,
    permission: PermissionNames.Commercials,
  },
  '/goods-in': {
    location: NavigationPaths.GoodsIn,
    permission: PermissionNames.Stock,
  },
  '/month-end-reconciliation': {
    location: NavigationPaths.Mer,
    featureFlag: PharmacyFeatureFlags.EnableMonthEndReconciliation,
    permission: PermissionNames.MonthEndReconciliation,
  },
  '/stock-tracking': {
    location: NavigationPaths.StockTracking,
    featureFlag: PharmacyFeatureFlags.EnableStockTracking,
    availableToClientType: ClientTypes.Pharmacy,
    permission: PermissionNames.Stock,
  },
  '/price-files': {
    location: NavigationPaths.PriceFiles,
    featureFlag: OrganistionFeatureFlags.EnablePriceFiles,
    availableToClientType: ClientTypes.Company,
    permission: PermissionNames.Commercials,
  },
  '/pharmacy-settings': {
    location: NavigationPaths.PharmacySettings,
    permission: PermissionNames.Manage,
    availableToClientType: ClientTypes.Company,
  },
}

const Navigation: React.FC<PropsWithChildren> = ({ children }) => {
  const mobileNavigationOpen = useRecoilValue(mobileNavigationOpenState)
  const setMobileNavigationOpen = useSetRecoilState(mobileNavigationOpenState)
  const { chatService } = useContext(ServiceContext)
  const featureFlagsForClient = useRecoilValue(
    featureFlagsForSelectedClientState
  )
  const userPermissionsForSelectedClient = useRecoilValue(
    userPermissionsForSelectedClientState
  )

  const handleLinkNavigation = () => {
    setMobileNavigationOpen(false)
  }

  const handleChatLinkClick = () => {
    chatService.openChat()
    setMobileNavigationOpen(false)
  }

  const renderLink = (
    isDisabled: boolean,
    location: string,
    text: string,
    testId: string,
    icon: JSX.Element,
    isExternal: boolean = false,
    onClick?: () => void,
    type: 'standard' | 'help' = 'standard'
  ) => {
    return (
      <Link
        component={RouterLink}
        to={isDisabled ? '#' : location}
        onClick={() => {
          if (onClick) {
            onClick()
          } else if (!isDisabled) handleLinkNavigation()
        }}
        data-testid={testId}
        target={isExternal ? '_blank' : undefined}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: theme.spacing(1),
            paddingY: theme.spacing(0.5),
            paddingLeft: theme.spacing(2),
            color: isDisabled
              ? theme.palette.grey[400]
              : type === 'help'
              ? theme.palette.grey[800]
              : theme.palette.common.black,
            ':hover': {
              color: isDisabled
                ? theme.palette.grey[400]
                : theme.palette.common.white,
              backgroundColor: isDisabled
                ? 'inherit'
                : theme.palette.primary.main,
            },
            cursor: isDisabled ? 'default' : 'pointer',
          }}
        >
          {icon}
          <Typography
            variant="body1"
            sx={{
              fontSize: type === 'help' ? '0.9rem' : '1rem',
              color: 'inherit',
              cursor: 'inherit',
            }}
          >
            {text}
          </Typography>
          {isExternal && (
            <OpenInNew
              sx={{
                fontSize: '1.2em',
              }}
            />
          )}
        </Box>
      </Link>
    )
  }

  const renderPageLink = (
    navigationLinkMapKey: string,
    text: string,
    testId: string,
    icon: JSX.Element
  ) => {
    const navigationLinkMap = navigationLinkMaps[navigationLinkMapKey]

    const isDisabled = Boolean(
      (navigationLinkMap.featureFlag &&
        !featureFlagsForClient?.setFeatureFlags?.includes(
          navigationLinkMap.featureFlag
        )) ||
        (navigationLinkMap.permission &&
          !userPermissionsForSelectedClient?.includes(
            navigationLinkMap.permission
          )) ||
        (navigationLinkMap.availableToClientType &&
          navigationLinkMap.availableToClientType !==
            featureFlagsForClient?.clientType)
    )
    return renderLink(
      isDisabled,
      navigationLinkMap.location,
      text,
      testId,
      icon
    )
  }

  return (
    <Drawer
      variant={'temporary'}
      open={mobileNavigationOpen}
      ModalProps={{
        keepMounted: true,
        onClose: (e, reason) => {
          if (reason === 'backdropClick') {
            setMobileNavigationOpen(false)
          }
        },
      }}
      PaperProps={{ elevation: 0, sx: drawerPaperStyle }}
    >
      <Box
        data-testid="navigation-container"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(2),
          }}
          data-testid="logo-container"
        >
          <Logo></Logo>
        </Box>
        <Box
          sx={{
            marginTop: '12px',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'space-between',
          }}
        >
          <Box
            component="ul"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: theme.spacing(2),
            }}
          >
            <li>
              {renderPageLink(
                NavigationPaths.Analytics,
                translation.Links.Analytics,
                'analytics-link',
                <AutoGraphIcon sx={{ cursor: 'inherit', color: 'inherit' }} />
              )}
            </li>
            <li>
              {renderPageLink(
                NavigationPaths.GoodsIn,
                translation.Links.GoodsIn,
                'goods-in-link',
                <LocalShippingOutlinedIcon
                  sx={{ cursor: 'inherit', color: 'inherit' }}
                />
              )}
            </li>
            <li>
              {renderPageLink(
                NavigationPaths.Mer,
                translation.Links.MonthEndReconciliation,
                'mer-link',
                <CurrencyExchangeOutlinedIcon
                  sx={{ cursor: 'inherit', color: 'inherit' }}
                />
              )}
            </li>
            <li>
              {renderPageLink(
                NavigationPaths.StockTracking,
                translation.Links.StockTracking,
                'stock-tracking-link',
                <LocationSearchingOutlinedIcon
                  sx={{ cursor: 'inherit', color: 'inherit' }}
                />
              )}
            </li>
            <li>
              {renderPageLink(
                NavigationPaths.PriceFiles,
                translation.Links.PriceFiles,
                'price-files-link',
                <PriceCheckOutlinedIcon
                  sx={{ cursor: 'inherit', color: 'inherit' }}
                />
              )}
            </li>
          </Box>
          <Box component="ul" sx={{ marginBottom: theme.spacing(2) }}>
            {configurationSettings.ShowChat && (
              <li>
                {renderLink(
                  false,
                  '#',
                  locale.translation.Navigation.Links.ChatWithUs,
                  'chat-link',
                  <MarkChatReadOutlinedIcon
                    sx={{ cursor: 'inherit', color: 'inherit' }}
                  />,
                  false,
                  handleChatLinkClick,
                  'help'
                )}
              </li>
            )}
            <li>
              {renderLink(
                false,
                `${configurationSettings.StockRxSiteUrl}/help`,
                locale.translation.Navigation.Links.HelpCentre,
                'help-centre-link',
                <OndemandVideoOutlinedIcon
                  sx={{ cursor: 'inherit', color: 'inherit' }}
                />,
                true,
                undefined,
                'help'
              )}
            </li>
            <li>
              {renderLink(
                false,
                `${configurationSettings.StockRxSiteUrl}/help/management-training`,
                locale.translation.Navigation.Links.ManagementTraining,
                'help-management-training-link',
                <OndemandVideoOutlinedIcon
                  sx={{ cursor: 'inherit', color: 'inherit' }}
                />,
                true,
                undefined,
                'help'
              )}
            </li>
          </Box>
        </Box>
        {children}
      </Box>
    </Drawer>
  )
}

export default Navigation
