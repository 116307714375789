import Box from '@mui/material/Box'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import theme from '../../../styles/theme'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import CreateIcon from '@mui/icons-material/Create'
import Button from '@mui/material/Button'
import { locale } from '../../../locales'
import { SxProps, Theme } from '@mui/material/styles'

export interface EditableSectionAdditionalButton {
  label: string
  onClick: () => void
}

export interface EditableSectionProps extends PropsWithChildren {
  sectionTitle: string
  updateDisabled: boolean
  onUpdate: () => void
  onCancel: () => void
  onEditingChanged: (editing: boolean) => void
  titleSx?: SxProps<Theme>
  additionalButtons?: EditableSectionAdditionalButton[]
  autoEdit?: boolean
  sx?: SxProps<Theme>
  isEditing?: boolean
  disableAutoClose?: boolean
}

const translation = locale.translation.SettingsPage.EditableSection

const EditableSection: FC<EditableSectionProps> = (props) => {
  const [editing, setEditing] = useState<boolean>(Boolean(props.autoEdit))
  const handleEditingChanged = (isEditing: boolean) => {
    setEditing(isEditing)
    props.onEditingChanged(isEditing)
  }

  useEffect(() => {
    if (props.isEditing != null) {
      setEditing(props.isEditing)
    }
  }, [props.isEditing])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          //   justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            paddingY: theme.spacing(1),
            margin: 0,
            color: theme.palette.grey[700],
            ...props.titleSx,
          }}
          variant="button"
        >
          {props.sectionTitle}
        </Typography>
        {!Boolean(props.autoEdit) && (
          <ButtonBase
            data-testid="edit-button"
            sx={{
              padding: theme.spacing(1),
              display: editing ? 'none' : 'block',
              color: theme.palette.grey[500],
              backgroundColor: 'transparent',
              '& :hover': { color: theme.palette.grey[700] },
            }}
            onClick={() => handleEditingChanged(true)}
          >
            <CreateIcon fontSize="small" />
          </ButtonBase>
        )}
      </Box>
      {props.children}
      {editing && (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            marginTop: theme.spacing(1),
            justifyContent: 'space-between',
            maxHeight: theme.spacing(4),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: theme.spacing(1),
              flexGrow: 1,
            }}
          >
            {props.additionalButtons &&
              props.additionalButtons.map((b) => (
                <Button
                  disableElevation
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={b.onClick}
                >
                  {b.label}
                </Button>
              ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: theme.spacing(1),
              flexGrow: 1,
            }}
          >
            <Button
              data-testid="cancel-button"
              disableElevation
              size="small"
              variant="contained"
              color="error"
              onClick={() => {
                handleEditingChanged(false)
                props.onCancel()
              }}
            >
              {translation.Cancel}
            </Button>
            <Button
              data-testid="update-button"
              disableElevation
              size="small"
              variant="contained"
              color="primary"
              disabled={props.updateDisabled}
              onClick={() => {
                if (!props.disableAutoClose) {
                  handleEditingChanged(false)
                }
                props.onUpdate()
              }}
            >
              {translation.Update}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default EditableSection
