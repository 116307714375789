import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { SxProps, Theme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as yup from 'yup'
import { locale } from '../../locales'
import theme from '../../styles/theme'
import RevealPasswordInput from './RevealPasswordInput'
import { useReducer } from 'react'

const minimumCharacters = 8

const newPasswordValidationSchema = yup.object({
  newPassword: yup.string().required('REQUIRED').min(minimumCharacters, 'MIN'),
  confirmNewPassword: yup
    .string()
    .required('REQUIRED')
    .min(minimumCharacters, 'MIN')
    .oneOf([yup.ref('newPassword')], 'MATCH'),
})

const validateForm = (values: {
  newPassword: string
  confirmNewPassword: string
}) => {
  try {
    newPasswordValidationSchema.validateSync(values, { abortEarly: false })
    return { newPassword: '', confirmNewPassword: '' }
  } catch (err: any) {
    const errors = {
      newPassword: '',
      confirmNewPassword: '',
    }
    if (err.inner) {
      err.inner.forEach((validationError: any) => {
        if (validationError.path === 'newPassword') {
          errors.newPassword = validationError.message
        } else if (validationError.path === 'confirmNewPassword') {
          errors.confirmNewPassword = validationError.message
        }
      })
    }
    return errors
  }
}

interface ChangePasswordFragmentState {
  newPassword: string
  confirmNewPassword: string
  code: string
  errors: {
    newPassword: string
    confirmNewPassword: string
  }
}

interface SetNewPasswordAction {
  type: 'SET_NEW_PASSWORD'
  payload: string
}

interface SetConfirmNewPasswordAction {
  type: 'SET_CONFIRM_NEW_PASSWORD'
  payload: string
}

interface SetCodeAction {
  type: 'SET_CODE'
  payload: string
}

type ChangePasswordFragmentAction =
  | SetNewPasswordAction
  | SetConfirmNewPasswordAction
  | SetCodeAction

const reducer = (
  state: ChangePasswordFragmentState,
  action: ChangePasswordFragmentAction
): ChangePasswordFragmentState => {
  let nextState: ChangePasswordFragmentState = state
  switch (action.type) {
    case 'SET_NEW_PASSWORD':
      nextState = {
        ...state,
        newPassword: action.payload,
        errors: {
          ...state.errors,
          newPassword: '',
        },
      }
      break
    case 'SET_CONFIRM_NEW_PASSWORD':
      nextState = {
        ...state,
        confirmNewPassword: action.payload,
        errors: {
          ...state.errors,
          confirmNewPassword: '',
        },
      }
      break
    case 'SET_CODE':
      nextState = {
        ...state,
        code: action.payload,
      }
      break
  }
  return {
    ...nextState,
    errors: validateForm({
      newPassword: nextState.newPassword,
      confirmNewPassword: nextState.confirmNewPassword,
    }),
  }
}

export const ChangePasswordFragment: React.FC<{
  cardStyle: SxProps<Theme>
  handleSubmit: (newPassword: string, code: string) => void
  newPasswordError: string
  isForgot: boolean
}> = ({ cardStyle, handleSubmit, newPasswordError, isForgot }) => {
  const [state, dispatch] = useReducer(reducer, {
    newPassword: '',
    confirmNewPassword: '',
    code: '',
    errors: {
      newPassword: '',
      confirmNewPassword: '',
    },
  })

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit(state.newPassword, state.code)
      }}
      data-testid="change-password-form"
    >
      <Card variant="outlined" sx={cardStyle}>
        <CardContent sx={{ padding: theme.spacing(1) }}>
          <Stack spacing={2}>
            <Typography variant="h5">
              {isForgot
                ? locale.translation.ForgotPasswordForm.Title
                : locale.translation.ChangePasswordForm.Title}
            </Typography>
            {isForgot && (
              <>
                <Typography variant="subtitle1">
                  {locale.translation.ForgotPasswordForm.CodeDescription}
                </Typography>
                <TextField
                  fullWidth
                  id="code"
                  name="code"
                  autoComplete="off"
                  inputProps={{
                    autoComplete: 'one-time-code',
                    form: {
                      autoComplete: 'off',
                    },
                  }}
                  label={
                    locale.translation.ForgotPasswordForm.Fields.Code.Label
                  }
                  placeholder={
                    locale.translation.ForgotPasswordForm.Fields.Code
                      .Placeholder
                  }
                  value={state.code}
                  onChange={(e) =>
                    dispatch({ type: 'SET_CODE', payload: e.target.value })
                  }
                />
              </>
            )}
            <RevealPasswordInput
              label={
                locale.translation.ChangePasswordForm.Fields.NewPassword.Label
              }
              placeholder={
                locale.translation.ChangePasswordForm.Fields.NewPassword
                  .Placeholder
              }
              error={Boolean(state.errors.newPassword)}
              value={state.newPassword}
              onChanged={(v) =>
                dispatch({ type: 'SET_NEW_PASSWORD', payload: v })
              }
              helperText={
                state.errors.newPassword === 'REQUIRED'
                  ? locale.translation.ChangePasswordForm.Fields.NewPassword
                      .Validation.Required
                  : state.errors.newPassword === 'MIN'
                  ? locale.translation.ChangePasswordForm.Fields.NewPassword.Validation.Minimum(
                      minimumCharacters
                    )
                  : null
              }
            />
            <TextField
              fullWidth
              id="confirmNewPassword"
              name="confirmNewPassword"
              autoComplete="off"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
              label={
                locale.translation.ChangePasswordForm.Fields.ConfirmNewPassword
                  .Label
              }
              placeholder={
                locale.translation.ChangePasswordForm.Fields.ConfirmNewPassword
                  .Placeholder
              }
              type="password"
              value={state.confirmNewPassword}
              onChange={(e) =>
                dispatch({
                  type: 'SET_CONFIRM_NEW_PASSWORD',
                  payload: e.target.value,
                })
              }
              error={Boolean(state.errors.confirmNewPassword)}
              helperText={
                state.errors.confirmNewPassword === 'REQUIRED'
                  ? locale.translation.ChangePasswordForm.Fields
                      .ConfirmNewPassword.Validation.Required
                  : state.errors.confirmNewPassword === 'MIN'
                  ? locale.translation.ChangePasswordForm.Fields.ConfirmNewPassword.Validation.Minimum(
                      minimumCharacters
                    )
                  : state.errors.confirmNewPassword === 'MATCH'
                  ? locale.translation.ChangePasswordForm.Fields
                      .ConfirmNewPassword.Validation.PasswordsDoNotMatch
                  : null
              }
            />
            {newPasswordError && (
              <Alert variant="filled" severity="error">
                {newPasswordError}
              </Alert>
            )}
          </Stack>
        </CardContent>
        <CardActions>
          <Button color="primary" variant="contained" fullWidth type="submit">
            {locale.translation.ChangePasswordForm.Buttons.Submit}
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}
