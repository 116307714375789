import { FC, useContext, useReducer } from 'react'
import { useRecoilValue } from 'recoil'
import * as yup from 'yup'
import { locale } from '../../../locales'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { selectedClientState } from '../../../state/SelectedPharmacyState'
import theme from '../../../styles/theme'
import { Product } from '../entities/Product'
import { ProductStockUpdateRequest } from '../entities/ProductStockUpdateMessage'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'

interface InputStockModalProps {
  product: Product
  onSubmitCallback: () => void
  isReset: boolean
}

interface FormValues {
  currentStockPacks: number | undefined
  currentStockUnits: number | undefined
}
const translation =
  locale.translation.StockTrackingPage.TabLiveStock.InputStockForm

export const validationSchema = yup.object({
  currentStockPacks: yup
    .number()
    .integer('INTEGER')
    .required('CURRENT_STOCK_PACKS_REQUIRED')
    .min(0, 'CURRENT_STOCK_PACKS_MIN'),

  currentStockUnits: yup
    .number()
    .integer('INTEGER')
    .required('CURRENT_STOCK_UNITS_REQUIRED')
    .min(0, 'CURRENT_STOCK_UNITS_MIN'),
})

export const validateInputStockForm = (values: FormValues) => {
  try {
    validationSchema.validateSync(values, { abortEarly: false })
    return { currentStockPacks: '', currentStockUnits: '' }
  } catch (err: any) {
    const errors = {
      currentStockPacks: '',
      currentStockUnits: '',
    }
    err.inner.forEach((validationError: any) => {
      if (validationError.path === 'currentStockPacks') {
        errors.currentStockPacks = validationError.message
      } else if (validationError.path === 'currentStockUnits') {
        errors.currentStockUnits = validationError.message
      }
    })
    return errors
  }
}

interface InputStockFormState {
  currentStockPacks: number | undefined
  currentStockUnits: number | undefined
  errors: {
    currentStockPacks: string
    currentStockUnits: string
  }
  isLoading: boolean
  error: string
  warning: string
}

interface SetCurrentStockPacksAction {
  type: 'SET_CURRENT_STOCK_PACKS'
  payload: number | undefined
}

interface SetCurrentStockUnitsAction {
  type: 'SET_CURRENT_STOCK_UNITS'
  payload: number | undefined
}

interface SetErrorAction {
  type: 'SET_ERROR'
  payload: string
}

interface SetLoadingAction {
  type: 'SET_LOADING'
  payload: boolean
}

interface SetFormValuesAction {
  type: 'SET_FORM_VALUES'
  payload: {
    currentStockPacks: number
    currentStockUnits: number
    warning: string
  }
}

type InputStockFormActions =
  | SetCurrentStockPacksAction
  | SetCurrentStockUnitsAction
  | SetErrorAction
  | SetLoadingAction
  | SetFormValuesAction

const reducer = (
  state: InputStockFormState,
  action: InputStockFormActions
): InputStockFormState => {
  let nextState: InputStockFormState = state
  switch (action.type) {
    case 'SET_CURRENT_STOCK_PACKS':
      nextState = { ...state, currentStockPacks: action.payload }
      break
    case 'SET_CURRENT_STOCK_UNITS':
      nextState = { ...state, currentStockUnits: action.payload }
      break
    case 'SET_ERROR':
      nextState = { ...state, error: action.payload, isLoading: false }
      break
    case 'SET_LOADING':
      nextState = { ...state, isLoading: action.payload }
      break
    case 'SET_FORM_VALUES':
      nextState = {
        ...state,
        currentStockPacks: action.payload.currentStockPacks,
        currentStockUnits: action.payload.currentStockUnits,
        warning: action.payload.warning,
      }
      break
  }
  return {
    ...nextState,
    errors: validateInputStockForm({
      currentStockPacks: nextState.currentStockPacks,
      currentStockUnits: nextState.currentStockUnits,
    }),
  }
}

const InputStockForm: FC<InputStockModalProps> = ({
  product,
  onSubmitCallback,
  isReset,
}) => {
  const { platformHttpService } = useContext(ServiceContext)
  const selectedClient = useRecoilValue(selectedClientState)

  const [state, dispatch] = useReducer(reducer, {
    currentStockPacks: 0,
    currentStockUnits: 0,
    errors: {
      currentStockPacks: '',
      currentStockUnits: '',
    },
    isLoading: false,
    error: '',
    warning: '',
  } as InputStockFormState)

  const handleInputStockBlur = () => {
    let currentStockUnits = state.currentStockUnits ?? 0

    //Perform Calculations
    if (currentStockUnits >= product.packSize) {
      const calculatedUnits = currentStockUnits % product.packSize
      const calculatedPacks = Math.floor(currentStockUnits / product.packSize)

      let totalPacks = state.currentStockPacks ?? 0
      totalPacks = totalPacks + calculatedPacks

      //Update
      dispatch({
        type: 'SET_FORM_VALUES',
        payload: {
          currentStockPacks: totalPacks,
          currentStockUnits: calculatedUnits,
          warning: translation.Notifications.NotificationAlreadyCalculatedSplit(
            currentStockUnits,
            calculatedPacks,
            calculatedUnits
          ),
        },
      })
    }
  }

  // On submit send the request to the API to create the new product in the
  // stock tracking table, then close the modal
  // If this is a RESET, we will updated the product stock values,
  // instead of adding a new product
  const handleSubmit = async (formValues: FormValues) => {
    dispatch({ type: 'SET_LOADING', payload: true })

    const body: ProductStockUpdateRequest = {
      productId: product.productId,
      packSize: product.packSize,
      actionType: 'RESET',
      packsQuantity: state.currentStockPacks!,
      unitsQuantity: state.currentStockUnits!,
    }
    const response = await platformHttpService.postAsync(
      PlatformApiPaths.ResetProductStock(selectedClient?.clientId!),
      body,
      'StockBaseUrl'
    )
    if (!response.hasErrors) {
      onSubmitCallback()
      dispatch({ type: 'SET_LOADING', payload: false })
    } else {
      dispatch({
        type: 'SET_ERROR',
        payload: GetErrorMessage(response.statusCode),
      })
    }
  }

  const getHelperText = (errorKey: string | null) => {
    switch (errorKey) {
      case 'INTEGER':
        return translation.Validation.CurrentStockPacksOrUnitsValidationInteger
      case 'CURRENT_STOCK_PACKS_REQUIRED':
        return translation.Validation.CurrentStockPacksValidationRequired
      case 'CURRENT_STOCK_PACKS_MIN':
        return translation.Validation.CurrentStockPacksValidationMin
      case 'CURRENT_STOCK_UNITS_REQUIRED':
        return translation.Validation.CurrentStockUnitsValidationRequired
      case 'CURRENT_STOCK_UNITS_MIN':
        return translation.Validation.CurrentStockUnitsValidationMin
      default:
        return null
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.spacing(2),
        '&:focus-visible': { outline: 'none' },
        boxShadow: 'none',
      }}
      component={'form'}
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit({
          currentStockPacks: state.currentStockPacks,
          currentStockUnits: state.currentStockUnits,
        } as FormValues)
      }}
    >
      {!isReset && product && (
        <Typography
          variant="h6"
          sx={{ fontSize: '1.2em', marginBottom: theme.spacing(1) }}
          align="center"
        >
          {translation.ProductTitle(
            product.productName,
            product.packSize!,
            product.unitOfMeasure,
            product.subPackDescription
          )}
        </Typography>
      )}
      <TextField
        id="currentStockPacks"
        name="currentStockPacks"
        label={translation.Fields.StockPacks.Label}
        type="number"
        data-testid="current-stock-packs-input-field"
        value={state.currentStockPacks}
        error={Boolean(state.errors.currentStockPacks)}
        helperText={getHelperText(state.errors.currentStockPacks)}
        onChange={(e) => {
          dispatch({
            type: 'SET_CURRENT_STOCK_PACKS',
            payload:
              e.target.value === '' ? undefined : parseInt(e.target.value, 10),
          })
        }}
        onBlur={handleInputStockBlur}
      ></TextField>
      <TextField
        id="currentStockUnits"
        name="currentStockUnits"
        label={translation.Fields.StockUnits.Label}
        type="number"
        data-testid="current-stock-units-input-field"
        value={state.currentStockUnits}
        error={Boolean(state.errors.currentStockUnits)}
        helperText={getHelperText(state.errors.currentStockUnits)}
        onChange={(e) => {
          dispatch({
            type: 'SET_CURRENT_STOCK_UNITS',
            payload:
              e.target.value === '' ? undefined : parseInt(e.target.value, 10),
          })
        }}
        onBlur={handleInputStockBlur}
      ></TextField>

      <LoadingButton
        data-testid="complete-adding-stock-button"
        type="submit"
        disableElevation
        variant="contained"
        color="primary"
        loading={state.isLoading}
        disabled={
          Boolean(state.errors.currentStockPacks) ||
          Boolean(state.errors.currentStockUnits)
        }
        sx={{
          minWidth: '160px',
        }}
      >
        {translation.Buttons.Submit(
          state.currentStockPacks,
          state.currentStockUnits,
          isReset
        )}
      </LoadingButton>
      {state.warning && (
        <Alert variant="filled" severity="warning">
          {state.warning}
        </Alert>
      )}
      {state.error && (
        <Alert variant="filled" severity="error">
          {state.error}
        </Alert>
      )}
    </Box>
  )
}

export default InputStockForm
